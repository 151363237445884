import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Children } from "react"

const ProductHero = ({
  title,
  subtitle,
  html,
  showCallToAction = false,
  ctaText = "Start Your Free Trial",
  children,
}) => (
  <section className="page-hero py-6">
    <div className="container has-text-centered">
      <h1 className="title has-text-centered has-text-weight-light is-size-2 is-size-3-mobile">
        {title}
      </h1>
      {html && (
        <div
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        ></div>
      )}
      {subtitle && (
        <h2 className="subtitle has-text-centered mt-4 is-size-6">
          {subtitle}
        </h2>
      )}
      {showCallToAction && (
        <button
          className="button is-link is-uppercase"
          style={{
            padding: "20px 40px",
            fontSize: "12px",
            fontWeight: "600",
          }}
        >
          {ctaText}
        </button>
      )}
      {children}
    </div>
  </section>
)

ProductHero.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  subtitle: PropTypes.string,
  showCallToAction: PropTypes.bool,
}

export default ProductHero
