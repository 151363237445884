import React from "react"
import Layout from "../components/layout"
import AgenciesMarquee from "../components/agencies-marquee"
import ProductHero from "../components/product-hero"
import Img from "gatsby-image"
import { Link } from "gatsby"
import SEO from "../components/seo"

class PressPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Layout>
        <SEO
          title="inPowered in the News"
          description="Learn what the press is saying about inPowered."
        />
        <ProductHero title={[<strong>inPowered</strong>, " in the News"]} />
        <div class="blog-container mb-6">
          <div class="blog-grid">
            {this.props.data.allWordpressWpPressReleases.nodes.map(
              ({ featured_media, acf: { link, title, summary } }) => (
                <Link className="blog-post" to={link} target="_blank">
                  {featured_media && (
                    <Img
                      style={{ width: "100%" }}
                      fluid={featured_media.localFile.childImageSharp.fluid}
                    />
                  )}
                  <div className="px-5 py-5">
                    <h3
                      className="has-text-weight-semibold mb-3"
                      style={{
                        fontSize: "16px",
                        color: "#2E2E2E",
                        lineHeight: "23px",
                      }}
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></h3>
                    <p
                      style={{
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        color: "#515C65",
                        lineHeight: "24px",
                      }}
                      dangerouslySetInnerHTML={{ __html: summary }}
                    ></p>
                  </div>
                </Link>
              )
            )}
          </div>
        </div>

        <AgenciesMarquee />
      </Layout>
    )
  }
}

export default PressPage

export const pageQuery = graphql`
  query {
    allWordpressWpPressReleases(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        acf {
          title
          link
          summary
        }
        featured_media {
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
